<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label-for="input-default"
              >
                <v-select
                  v-model="organizationSelected"
                  :reduce="option => option.value"
                  :options="organizations"
                  placeholder="Cơ quan chủ quản"
                  @input="onChangeOrganizations()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <div class="text-right">
                <b-form-group>
                  <b-button
                    variant="primary"
                    class="ml-1"
                    @click="btnSearchClick()"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      class="align-text-top"
                    />
                    Tìm kiếm
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-b-modal.departmentSaveModal
                    variant="success"
                    class="ml-md-1 mt-1 mt-md-0"
                    @click="onCreateDepartment"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="align-text-top"
                    /> Thêm mới
                  </b-button>
                </b-form-group>
              </div>

            </b-col>
            <!-- Per Page -->
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                mode="remote"
                class="my-table"
                style-class="vgt-table striped bordered research-table"
                row-style-class="vgt-row"
                :columns="columns"
                :rows="departments"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>

                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.departmentSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onEditDepartment(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDeleteDepartment(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                    </div>
                    <div class="d-flex mb-0 mt-1">
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <DepartmentSave
      ref="saveModalRef"
      :item="currentDepartment"
      @succeed="onSucceed"
    />
  </b-container>

</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import DepartmentSave from '@/views/admin/category/department/DepartmentSave.vue'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Departments',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormGroup,
    VueGoodTable,
    vSelect,
    DepartmentSave,
  },
  data() {
    return {
      organizationSelected: {},
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        typeId: null,
        searchTerm: '',
        status: 1,
        sort: '',
      },
      currentDepartment: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'STT',
          field: 'rowNum',
          width: '5%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên khoa',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Mã khoa',
          field: 'code',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          width: '200px',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Đơn vị chủ quản',
          field: 'organizationName',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            placeholder: 'Tất cả',
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: [],
          },
          width: '150px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      departments: 'department/departments',
      totalRows: 'department/totalRows',
      statuses: 'department/statuses',
      organizations: 'dropdown/organizations',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.DEPARTMENT)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.DEPARTMENT)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.DEPARTMENT)
    },
  },
  async created() {
    this.isLoading = true
    try {
      const cUser = getUser()
      this.filter.organizationId = cUser.orgId
      // @TODO: Loc theo don vi
      await this.getOrganizations({ parentId: getUser().orgId })
      if (this.organizations && this.organizations.length) {
        this.organizationSelected = this.organizations.find(e => e.value === getUser().orgId).value
        this.filter.organizationId = this.organizationSelected
        await this.btnSearchClick()
      }
      const statusesCV = this.statuses.map(item => ({ value: item.value, text: item.label }))
      this.$set(this.columns[4].filterOptions, 'filterDropdownItems', statusesCV)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getOrganizations: 'dropdown/getOrganization',
      readDepartments: 'department/readDepartments',
      updateDepartments: 'department/updateDepartments',
      deleteDepartments: 'department/deleteDepartments',
    }),

    async onChangeOrganizations() {
      this.filter.organizationId = this.organizationSelected
      await this.btnSearchClick()
    },
    async btnSearchClick() {
      // this.organizations = [];
      this.isLoading = true
      try {
        await Promise.all([
          this.readDepartments(this.filter),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onCreateDepartment() {
      this.currentDepartment = undefined
    },
    onEditDepartment(department) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = department
      this.currentDepartment = rest
    },
    onDeleteDepartment(department) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa phòng/ban <span class="text-danger">${department.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteDepartments(department.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.readDepartments(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDepartmentsFromStore()
    },
    async getDepartmentsFromStore() {
      this.isLoading = true
      try {
        await this.readDepartments(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDepartmentsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'orderNo') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getDepartmentsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ searchTerm: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDepartmentsFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getDepartmentsFromStore()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
